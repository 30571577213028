export const socialsData = {
    github: '',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/elizabeth-a-dickerson/',
    instagram: '',
    codepen: '',
    twitter: '',
    reddit: '',
    blogger: '',
    medium: '',
    stackOverflow: '',
    gitlab: '',
    youtube: 'https://www.youtube.com/c/ElizabethDickerson',
    imdb: 'https://www.imdb.com/name/nm13194424',
    email: 'mailto:contact@elliedickerson.com'
}