import React,{ useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ThemeContext } from '../../contexts/ThemeContext';
import { videoData} from '../../data/projectsData'

import './Projects.css'
import {RoundedSquareVideo} from "./RoundedSquareVideo";

function Projects() {

    const { theme } = useContext(ThemeContext);

    
    const useStyles = makeStyles(() => ({
        viewAllBtn : {
            color: theme.tertiary, 
            backgroundColor: theme.primary,
            transition: 'color 0.2s',
            "&:hover": {
                color: theme.secondary, 
                backgroundColor: theme.primary,
            }
        },
        viewArr : {
            color: theme.tertiary, 
            backgroundColor: theme.secondary70,
            width: '40px',
            height: '40px',
            padding: '0.5rem',
            fontSize: '1.05rem',
            borderRadius: '50%',
            cursor: 'pointer',
            transition: 'background-color 0.2s',
            "&:hover": {
                color: theme.tertiary, 
                backgroundColor: theme.secondary,
            }
        },
    }));

    return (
        <>
            {videoData.length > 0 && (
                <div className="projects" id="reels" style={{backgroundColor: theme.secondary}}>
                    <div className="projects--header">
                        <h1 style={{color: theme.primary}}>Reels</h1>
                    </div>
                    <div className="projects--body">
                        <div className="projects--bodyContainer">
                            {videoData.slice(0, 4).map(project => (
                                <RoundedSquareVideo
                                    videoId={project.videoId} />
                            ))}
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default Projects
