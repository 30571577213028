import React from 'react';
import './RoundedSquareVideo.css';

export const RoundedSquareVideo = ({videoId}) => {
    return (
        <div className="rounded-rectangle">
            <iframe
                className="video-iframe"
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube video"
            ></iframe>
        </div>
    );
}